export const OfficerDashboardData = [
    {
        Race: '',
        Candidates: 30,
    },
    {
        Race: '',
        Candidates: 50,
    },
    {
        Race: '',
        Candidates: 28,
    },
    {
        Race: '',
        Candidates: 20,
    },
    {
        Race: '',
        Candidates: 27,
    }
]
