import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';


function Index() {
    return (
        <Fragment>
            <footer>
                <p>&copy; 2023 URM Application. All rights reserved.</p>
            </footer>
        </Fragment >
    )
}

export default Index;